import React, { useEffect } from 'react'
import Layout from '../../Components/Layout/Layout'
import { Helmet } from 'react-helmet'

const AmlPolicy = () => {
    const PrivacyPolicyData = {
            mainInformation: "Pioneer Capital Ltd. is committed to the highest standards of compliance against money laundering (AML) and anti-terrorist financing (CTF). The objective of the Company's Policy against Money Laundering and Terrorism Financing is to actively prevent the risks of these matters. To help the government combat the financing of terrorism and money laundering activities, the law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. We have an obligation to report suspicious client activity relevant to money laundering.",
            menus: [
                {
                    title: 'Money Laundering:*',
                    subMenus: [
                        'The process of converting funds received from illegal activities (such as fraud, corruption, terrorism, etc.) into other funds or investments that appear legitimate to hide or distort the actual source of funds. The money laundering process can be divided into three sequential stages:',
                        'Placement. At this stage, the funds are turned into financial instruments, such as checks, bank accounts, and money transfers, or they can be used to buy high-value goods that can be resold. They can also be physically deposited in banks and non-bank institutions (e.g., exchange houses). To avoid suspicion on the part of the company, the launderer can also make several deposits instead of depositing the entire sum at once; this form of placement is called smurfing.',
                        'Layering. The funds are transferred or moved to other accounts and other financial instruments. It is done to disguise the origin and interrupt the indication of the entity that carried out the multiple financial transactions. Moving funds and changing their form makes it difficult to track the money that is being laundered.',
                        'Integration. The funds are re-circulated as legitimate to purchase goods and services.',
                        'Pioneer Capital Ltd. adheres to the principles of Anti-Money Laundering and actively prevents any action that targets or facilitates the legalization process of illegally obtained funds. The AML policy means preventing the use of the company’s services by criminals, with the objective of money laundering, terrorist financing, or other criminal activity.',
                        'To prevent money laundering, Pioneer Capital Ltd. does not accept or pay in cash under any circumstances. The company reserves the right to suspend the operation of any client, which may be considered illegal or, in the opinion of the staff, related to money laundering.',
                    ]
                },
                {
                    title: 'Company Procedures',
                    subMenus: [
                        'Pioneer Capital Ltd. will ensure that it is a real or legal person.',
                        'Pioneer Capital Ltd. will also perform all the necessary measures in accordance with the applicable laws and regulations, issued by the monetary authorities. The AML policy is being met within CFD of Pioneer Capital Ltd. by the following means:',
                        'Know your client’s policy and due diligence',
                        'Monitor customer activity',
                        'Registry maintenance',
                    ]
                },
                {
                    title: 'Know Your Customer',
                    subMenus: [
                        'Due to the company’s commitment to AML and KYC policies, each company customer must complete a verification procedure. Before Pioneer Capital Ltd. initiates any cooperation with the client, the company ensures that satisfactory evidence is presented or other measures are taken that produce satisfactory proof of the identity of any client or counterparty. The company also applies increased scrutiny to clients who are residents of other countries, identified by credible sources as countries that have inadequate AML standards or who may pose a high risk of crime and corruption and beneficial owners who reside in and whose funds are sourced from named countries.',
                    ]
                },
                {
                    title: 'Individual clients',
                    subMenus: [
                        'During the registration process, each client provides personal information, specifically: full name, birthdate, country of origin, and full residential address. The following documents are required to verify personal information: A client submits the following documents (in case the documents are written in non-Latin characters: to avoid delays in the verification process, it is necessary to provide a notarized translation of the document in English) due to KYC requirements and to confirm the indicated information:',
                        'Valid passport (showing the first page of the local or international passport, where the photo and signature are clearly visible); or Driving license with photograph; or National identity card (showing the front and back); Documents proving current permanent address (such as utility bills, bank statements, etc.) containing the customer’s full name and place of residence. These documents should not be older than 3 months from the filing date.',
                    ]
                },
                {
                    title: 'Corporate clients',
                    subMenus: [
                        'In the event that the applicant company is listed on a recognized or approved stock exchange or when there is independent evidence to show that the applicant is a wholly owned subsidiary or a subsidiary under the control of said company, no further steps will normally be taken to verify the identity. In the event that the company is not listed and none of the main directors or shareholders already has an account with Pioneer Capital Ltd., the following documentation must be provided:',
                        'Certificate of incorporation or any national equivalent; Memorandum and Articles of Association and statutory declaration or any national equivalent; Certificate of good standing or other proof of the company’s registered address; Registered Address: Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia Resolution of the board of directors to open an account and grant authority to those who will operate it; Copies of powers of attorney or other authorities granted by the directors in relation to the company; Proof of the identity of the directors in case they deal with Pioneer Capital Ltd. on behalf of the Client (in accordance with the individual identity verification rules described above); Proof of identity of the final beneficiary(s) and/or the person(s) under whose instructions the signers of the account are empowered to act (in accordance with the rules of individual identity verification described above).'
                    ]
                },
                {
                    title: 'Tracking customer activity',
                    subMenus: [
                        'In addition to collecting customer information, Pioneer Capital Ltd. continues to monitor the activity of each customer to identify and prevent any suspicious transactions. A suspicious transaction is known as a transaction that is not consistent with the legitimate business of the customer or with the transaction history of the regular customer. By tracking customer activity, Pioneer Capital Ltd. has implemented the named transactions monitoring system (both automatic and, if necessary, manual) to prevent criminals from using the company’s services.',
                    ]
                },
            ]
        };
    
        useEffect(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, []);
  return (
    <Layout>
        <Helmet>
                <title>AML Policy</title>
            </Helmet>
            <div className='privacy-policy-container'>
                <div className='privacy-policy-header'>
                    <div className='side-padding py-0'>
                        <div className='row m-0 p-0'>
                            <div className='py-5 col-md-6 p-0'>
                                <div className='py-md-5'>
                                    <div className='title-blue mb-4 '>
                                        <p className='m-0'>Pioneer Capital</p>
                                    </div>
                                    <p className='main-text mb-4'>AML Policy</p>
                                </div>
                            </div>
                            <div className='col-md-6 p-0 d-flex justify-content-end'>
                                <div className='privacy-policy-bg'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='detailed-info py-5'>
                    <div className='side-padding py-md-5'>
                        <p className='mb-4'>{PrivacyPolicyData?.mainInformation}</p>
                        <ol className='m-0 pl-4' type="1">
                            {
                                PrivacyPolicyData?.menus?.map((menu, index) => (
                                    <>
                                        <li key={index}>{menu?.title}</li>
                                        <ol className='m-0 pl-4' type="a">
                                            {
                                                menu?.subMenus?.map((subMenus, index) => (
                                                    <li key={index}>{subMenus}</li>
                                                ))
                                            }
                                        </ol>
                                    </>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </div>
    </Layout>
  )
}

export default AmlPolicy
