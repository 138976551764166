import React from 'react';
import './Footer.scss';
import { useNavigate } from 'react-router-dom';

export default function Footer() {

  const navigate = useNavigate();

  return (
    <div className='footer-container'>
      <div className='side-padding'>
        <div className='devider px-5 my-4 w-75 mx-auto'></div>
        <div className='pt-5 mt-5 row'>
          <div className='text-left col-md-5'>
            <img className='cursor-pointer' onClick={() => navigate('/')} height='40' src='Assets/Logo/Logo.png' alt='icon' />
            <p className='m-0 footer-main-text'>Pioneer Capital provides an entirely transparent access to the FOREX Market through powerful trading platform.</p>
            {/* ---------- Temporary removed ---------- */}
            {/* <div className='mt-5 pt-4'>
              <a>
                <img height={20} width={20} className='mr-4 cursor-pointer' src='Assets/Images/twitter-x-fill.svg' alt='icon' />
              </a>
              <a>
                <img height={24} width={24} className='mx-4 cursor-pointer' src='Assets/Images/facebook-fill.svg' alt='icon' />
              </a>
              <a>
                <img height={24} width={24} className='mx-4 cursor-pointer' src='Assets/Images/instagram-fill.svg' alt='icon' />
              </a>
              <a>
                <img height={24} width={24} className='ml-4 cursor-pointer' src='Assets/Images/linkedin-fill.svg' alt='icon' />
              </a>
            </div> */}
            <div className='mt-3 footer-links'>
              <p className='m-0 title'>Physical Address:</p>
              <p className='m-0'>811, Ascon House, Al Muteena, Dubai, United Arab Emirates.</p>
              <p className='m-0 title mt-3'>Registered Address:</p>
              <p className='m-0'>Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia. (Registration No. 2024-00474)</p>
            </div>
          </div>
          <div className='footer-links-container col-md-7 mt-5 mt-md-0'>
            <div className='footer-links mx-2'>
              <p className='mb-2 title'>Instruments</p>
              <p className='mb-2 link' onClick={() => navigate('/forex')}>Forex</p>
              <p className='mb-2 link' onClick={() => navigate('/crypto')}>Cryptocurrencies</p>
              <p className='mb-2 link' onClick={() => navigate('/metals')}>Metals</p>
              <p className='mb-2 link' onClick={() => navigate('/cfds')}>CFD’s</p>
              <p className='mb-2 link' onClick={() => navigate('/indices')}>Indices</p>
            </div>
            <div className='footer-links mx-2'>
              <p className='mb-2 title'>Platform</p>
              <p className='mb-2 link' onClick={() => navigate('/mt5')}>Meta Trader 5</p>
              <p className='mb-2 mt-4 title'>Disclosure</p>
              <p className='mb-2 link' onClick={() => navigate('/riskDisclosure')}>Risk Disclosure</p>
              <p className='mb-2 link' onClick={() => navigate('/privacyPolicy')}>Privacy Policy</p>
              <p className='mb-2 link' onClick={() => navigate('/executionRisk')}>Execution Risks</p>
              <p className='mb-2 link' onClick={() => navigate('/amlPolicy')}>AML Policy</p>
            </div>
            <div className='footer-links mx-2'>
              <p className='mb-2 title'>Quick Links</p>
              <p className='mb-2 link' onClick={() => navigate('/aboutUs')}>About us</p>
              <p className='mb-2 link' onClick={() => navigate('/contactUs')}>Contact us</p>
              <p className='mb-2 link' onClick={() => navigate('/partnership')}>Partner with us</p>
              <p className='mb-2 link' onClick={() => navigate('/blogs')}>Blogs</p>
            </div>
          </div>
        </div>
        <div className='contact-info-container mt-2 mt-md-5 pt-5'>
          <div className='bg-light-capsule my-3 mr-2'>
            <img height={20} width={20} src='Assets/Images/mail.svg' alt='icon' />
            <a className='mail-link' href="mailto:admin@pioneercapitalmarkets.com" target='_blank'>
              <p className='m-0 pb-1 pl-2'>admin@pioneercapitalmarkets.com</p>
            </a>
          </div>
          <div className='bg-light-capsule my-3 mx-2'>
            <img height={20} width={20} src='Assets/Images/phone.svg' alt='icon' />
            <a className='mail-link' href="tel:+971585802446" target='_blank'>
              <p className='m-0 pb-1 pl-2'>+971 58 580 2446 <span className='text-green'>(24/7) ●</span></p>
            </a>
          </div>

          <div className='my-3 ml-2'>
            <p className='m-0 copy-rights-text'>© 2024, Pioneer Capital Ltd.</p>
          </div>
        </div>
        <div className='devider px-5 my-4 w-75 mx-auto'></div>
        <div className='pt-3 mb-5'>
          <div className='warning-card p-2 p-md-3'>
            <p className='title'>General risk warning</p>
            <p className='description m-0'>CFDs are leveraged products. Trading foreign exchange on margin carries a high level of risk and may not be suitable for all investors. The high degree of leverage can work against you as well as for you. Before deciding to trade foreign exchange you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss in excess of your initial investment. Under no circumstances shall the Company have any liability to any person or entity for any loss or damage in whole or part caused by, resulting from, or relating to any transactions related to CFDs. You should be aware of all the risks associated with foreign exchange trading and seek advice from an independent financial advisor if you have any doubts.</p>
          </div>
          <div className='warning-card p-2 p-md-3 mt-4'>
            <p className='title'>Disclaimer</p>
            <p className='description m-0'>Restricted Regions: Pioneer Capital Ltd. does not provide services for citizens/residents of the United States, Cuba, Iraq, Myanmar, North Korea, Sudan. The services of Pioneer Capital Ltd. are not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</p>
          </div>
          <div className='warning-card p-2 p-md-3 mt-4'>
            <p className='description m-0'>Pioneer Capital Ltd. 811, Ascon House, Al Muteena, Dubai, United Arab Emirates. Registration No. 2024-00474 (St. Lucia) All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  )
}